import React from "react"
import { Box, UnorderedList } from "@chakra-ui/react"

const HeroUnorderedList: React.FC = ({ children }) => {
  return (
    <Box p={{ base: 2, md: 4 }} ml={{ base: 0, md: 8 }} fontSize='xl'>
      <UnorderedList
        maxW={{ base: "80vw", md: "35vw" }}
        fontWeight='bold'
        lineHeight={1.75}
      >
        {children}
      </UnorderedList>
    </Box>
  )
}

export default HeroUnorderedList
