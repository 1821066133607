import React from "react"
import { SimpleGrid } from "@chakra-ui/react"
import TechConsultingSection from "./hero/TechConsultingSection"
import FullStackDevSection from "./hero/FullStackDevSection"
import DevOpsSection from "./hero/DevOpsSection"
import Hero from "../../components/hero/Hero"

const HomePage: React.FC = () => {
  return (
    <>
      <Hero />
      <SimpleGrid columns={1} rows={{ base: 4, md: 2 }}>
        <FullStackDevSection />
        <TechConsultingSection />
        <DevOpsSection />
        {/*<HeroService icon={FaSitemap} heading='Startup Setup'>Get your business off the ground even faster. Let us*/}
        {/*  deal with setting up accounts and getting essential services like web, email, and phone*/}
        {/*  working.</HeroService>*/}
      </SimpleGrid>
    </>
  )
}

export default HomePage
