import React, { createRef, useEffect, useMemo, useState } from "react"
import { motion, TargetAndTransition } from "framer-motion"

const ScrollAnimated: React.FC<{
  delay?: number
  duration?: number
  inactive: TargetAndTransition
  active: TargetAndTransition
}> = ({ delay, inactive, active, duration, children }) => {
  const [show, setShow] = useState(false)
  const parentRef = createRef<HTMLDivElement>()

  useEffect(() => {
    let alreadyShown = false
    let timeout: NodeJS.Timer | undefined
    let _delay = Math.max(250, delay || 1000)
    const onScroll = () => {
      if (!alreadyShown) {
        const rect = parentRef.current?.getBoundingClientRect()
        if (!rect) return
        const scrollPos = Math.max(window.scrollY, window.innerHeight)
        if (scrollPos > rect.top) {
          timeout = setTimeout(() => {
            setShow(true)
          }, _delay)
        }
      }
    }
    document.addEventListener("scroll", onScroll)
    onScroll()
    return () => {
      document.removeEventListener("scroll", onScroll)
      if (timeout) clearTimeout(timeout)
    }
  })

  return useMemo(
    () => (
      <span ref={parentRef}>
        <motion.span
          style={{ visibility: show ? "inherit" : "hidden" }}
          animate={show ? active : inactive}
          transition={{ ease: "easeIn", duration: duration || 1.0 }}
        >
          {children}
        </motion.span>
      </span>
    ),
    [active, inactive, duration, show, parentRef, children]
  )
}

export default ScrollAnimated
