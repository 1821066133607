import React, { SVGProps } from "react"

interface Props extends SVGProps<any> {}

const ReltaLogoSVG: React.FC<Props> = ({ ...props }) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 551 175'
  >
    <defs>
      <linearGradient
        id='linear-gradient'
        x1='44.57'
        y1='132.72'
        x2='117.68'
        y2='43.54'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stopColor='#ac88a7' />
        <stop offset='1' stopColor='#531CB3' />
      </linearGradient>
      <linearGradient
        id='linear-gradient-2'
        x1='65.39'
        y1='145.62'
        x2='135.99'
        y2='59.51'
        xlinkHref='#linear-gradient'
      />
      <linearGradient
        id='linear-gradient-3'
        x1='119.33'
        y1='156.12'
        x2='198.94'
        y2='59.02'
        xlinkHref='#linear-gradient'
      />
      <linearGradient
        id='linear-gradient-4'
        x1='100.05'
        y1='143.47'
        x2='185.94'
        y2='38.71'
        xlinkHref='#linear-gradient'
      />
    </defs>
    <g id='Standard'>
      <text
        style={{
          fontSize: "158px",
          fill: "#531CB3",
          fontFamily: "museo-sans",
          fontWeight: 900
        }}
        transform='translate(149 143.47)'
      >
        <tspan style={{ letterSpacing: "-0.09em" }}>R</tspan>
        <tspan style={{ letterSpacing: "-0.04em" }} x='94.01' y='0'>
          el
        </tspan>
        <tspan style={{ letterSpacing: "-0.02em" }} x='221.35' y='0'>
          t
        </tspan>
        <tspan x='283.76' y='0'>
          a
        </tspan>
      </text>
      <g id='Bars'>
        <rect
          id='_1'
          data-name='1'
          style={{ fill: "url(#linear-gradient)" }}
          x='40'
          y='96.5'
          width='30'
          height='47'
        />
        <rect
          id='_3'
          data-name='3'
          style={{ fill: "url(#linear-gradient-2)" }}
          x='80'
          y='75.5'
          width='30'
          height='68'
        />
        <rect
          id='_4'
          data-name='4'
          style={{ fill: "url(#linear-gradient-3)" }}
          x='162'
          y='30.5'
          width='28'
          height='113'
        />
        <rect
          id='_3-2'
          data-name='3'
          style={{ fill: "url(#linear-gradient-4)" }}
          x='121.07'
          y='55.5'
          width='30'
          height='88.06'
        />
      </g>
    </g>
  </svg>
)

export default ReltaLogoSVG
