import React, { CSSProperties } from "react"
import { Box, chakra, Text, useBreakpointValue } from "@chakra-ui/react"
import styled from "styled-components"

const marqueeStyles: CSSProperties = {
  marginRight: "1rem",
  // paddingRight: '1em',
  color: "#666",
  opacity: "20%",
  width: "96px",
  maxHeight: "96px",
  // filter: 'grayscale(50%)',
  filter: "sepia(100%) hue-rotate(200deg)"
  // filter: 'hue-rotate(50deg)'
}

const ServiceContainer = chakra("div", {
  baseStyle: {
    position: "relative",
    minWidth: "80vw",
    // minHeight: '400px',
    paddingTop: 24
  }
})
// const _ServiceContainer = chakra(styled.div`
//   position: relative;
//   min-width: 80vw;
//   min-height: 400px;
//   //margin-top: 6rem;
//   padding-top: 6rem;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-around;
// `);

const ServiceBox = chakra(styled.div`
  font-weight: bold;
  //background: white;
  color: #531cb3;
  //color: white;
  border: 0.25em solid #531cb3;
  box-shadow: ${(props: any) => {
    if (props.noShadow) return "none"
    return `${props.rightShadow ? "-" : ""}.25em .45em 0 0 rgba(11, 11, 11, 1)`
  }};
`)

const HeroService: React.FC<{
  heading: string
  imageUrl: string
  left?: JSX.Element
  right?: JSX.Element
  marquee: (style: CSSProperties) => JSX.Element
}> = ({ heading, imageUrl, left, right, marquee, children }) => {
  const doEast = left && !right
  const imageShift = useBreakpointValue({
    base: "-.5em",
    md: "-1.5em"
  })
  return (
    <ServiceContainer
      px={8}
      py={24}
      display='flex'
      flexDirection={{ base: doEast ? "column-reverse" : "column", md: "row" }}
      alignItems='center'
      justifyContent={{ base: "stretch", md: "space-around" }}
    >
      {left}
      <Box>
        <Text
          color='black'
          fontSize={{ base: "4xl", md: "5xl", xl: "6xl" }}
          lineHeight={1.1}
          letterSpacing='-0.01em'
          // mb={32}
          maxW='500px'
          // mb={{base: '0em', md: 0}}
          fontWeight='800'
          // textShadow='1px 1px #CC00CC'
        >
          {heading}
        </Text>
        {marquee && <Box>{marquee(marqueeStyles)}</Box>}
        <Box>
          <ServiceBox
            position='relative'
            rightShadow={doEast}
            fontSize='lg'
            maxW='500px'
            ml='2em'
            mt={{ base: "9em", md: "2.5em" }}
            bg='white'
            p={6}
            zIndex={20}
          >
            <Text>{children}</Text>
          </ServiceBox>
          <Box position='relative' paddingBottom='175px'>
            <ServiceBox
              noShadow
              style={{
                position: "absolute",
                top: "-4rem",
                left: doEast ? undefined : imageShift,
                right: doEast ? imageShift : undefined,
                width: "90%",
                zIndex: 0
              }}
              background={`url(${imageUrl})`}
              backgroundSize='cover'
              h='200px'
            />
          </Box>
        </Box>
      </Box>
      {right}
    </ServiceContainer>
  )
}

export default HeroService
