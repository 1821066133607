import React from "react"
import { Box, Heading, Text, useBreakpointValue } from "@chakra-ui/react"
import ScrollAnimated from "../../../components/animations/ScrollAnimated"

const HeroHeading: React.FC = () => {
  const headingSize = useBreakpointValue({
    base: "3xl"
  })

  return (
    <Box maxW={{ base: "92.5vw", md: "80vw" }} px={6} textAlign='center'>
      {/*<Heading lineHeight={1} size={headingSize} mb={4} fontWeight='900'>We turn*/}
      {/*  {" "}<Text as='span' color='#531CB3'>dreams</Text>*/}
      {/*  {" into "}<Text as='span' color='#531CB3'>reality</Text>*/}
      {/*</Heading>*/}
      <Heading
        lineHeight={1}
        style={{
          textShadow: "0 0 4px 16px rgba(0,0,0,0.5)"
        }}
        size={headingSize}
        mb={4}
        fontWeight='900'
      >
        <ScrollAnimated
          inactive={{ opacity: 0, marginTop: "-1em" }}
          active={{ opacity: 1, marginTop: 0 }}
          delay={800}
        >
          <Text
            as='span'
            bgGradient='linear(to-tl, #531CB3, #FF0080)'
            bgClip='text'
          >
            Modern software
          </Text>
        </ScrollAnimated>
        <ScrollAnimated
          inactive={{ opacity: 0, marginTop: "-1em" }}
          active={{ opacity: 1, marginTop: 0 }}
          delay={1500}
        >
          {" for the "}
        </ScrollAnimated>
        <ScrollAnimated
          inactive={{ opacity: 0, marginTop: "-1em" }}
          active={{ opacity: 1, marginTop: 0 }}
          delay={2700}
        >
          <Text
            as='span'
            bgGradient='linear(to-br, yellow.600, orange.900)'
            bgClip='text'
          >
            next generation
          </Text>
        </ScrollAnimated>
        <ScrollAnimated
          inactive={{ opacity: 0, marginTop: "-1em" }}
          active={{ opacity: 1, marginTop: 0 }}
          delay={3500}
        >
          {" of business"}
        </ScrollAnimated>
      </Heading>
    </Box>
  )
}

export default HeroHeading
