import * as React from "react"
import { useMemo } from "react"
import { Flex } from "@chakra-ui/react"
import ReltaLogoSVG from "./ReltaLogoSVG"

export const ReltaLogo = () =>
  useMemo(() => {
    return (
      <Flex flexDirection='row' alignItems='center'>
        <ReltaLogoSVG
          width='150px'
          style={{
            marginRight: ".5em",
            padding: ".25em .5em",
            userSelect: "none"
          }}
        />

        {/*<Heading size='xl' color='#e30488' mt='-.35em' mb={4}*/}
        {/*         style={{letterSpacing: '-0.04em'}}*/}
        {/*         fontWeight='900'>Consulting</Heading>*/}

        {/*<Text borderWidth='1px 0' borderColor='#aaa' pt={2} pb={1} lineHeight={1}*/}
        {/*      style={{letterSpacing: '0.075em'}}*/}
        {/*      fontSize='sm' textTransform='uppercase'>Los Angeles, CA</Text>*/}
      </Flex>
    )
    // return <chakra.img src={logo} ref={ref} {...props} />
  }, [])
