import React from "react"
import { Flex } from "@chakra-ui/react"
import { ReltaLogo } from "../../logo/ReltaLogo"

const Navbar: React.FC = () => {
  return (
    <div
      style={{
        // zIndex: 20,
        // position: 'fixed',
        // top: 0,
        width: "100%",
        background: "rgba(255, 255, 255, 0.9)"
      }}
    >
      <Flex
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <ReltaLogo />
      </Flex>
    </div>
  )
}

export default Navbar
