import React, { useState } from "react"
import { motion, MotionProps, useReducedMotion } from "framer-motion"
import { BoxProps, chakra, Flex } from "@chakra-ui/react"

const MarqueeContainer = chakra("div")

const Marquee: React.FC<
  BoxProps & {
    duration?: number
    items: JSX.Element[]
  }
> = ({ duration, items, ...props }) => {
  const [elementStack] = useState(
    [...items, ...items]
      .slice(0, 25)
      .map(i => <div style={{ marginRight: "2rem" }}>{i}</div>)
  )
  const reduceMotion = useReducedMotion() || false
  duration = Math.max(duration || 20, 7.5)
  const transition = reduceMotion
    ? {}
    : ({
        ease: "linear",
        duration,
        repeat: Infinity,
        repeatType: "mirror"
      } as MotionProps)
  return (
    <MarqueeContainer
      {...props}
      position='absolute'
      top='11rem'
      left='0'
      zIndex={0}
      maxW='100%'
      overflow='clip'
    >
      <motion.div
        animate={{
          marginLeft: reduceMotion ? undefined : "-25vw"
          // marginRight: reduceMotion ? undefined : '25vw',
        }}
        transition={transition}
        style={
          {
            // width: 'auto'
          }
        }
      >
        <Flex flexDirection='row' alignItems='center'>
          {elementStack}
        </Flex>
      </motion.div>
    </MarqueeContainer>
  )
}
export default Marquee
