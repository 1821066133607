import React from "react"
import { Box, ListItem } from "@chakra-ui/react"
import HeroService from "../../../components/hero/HeroService"
import Marquee from "../../../components/animations/Marquee"
import LogoIcon from "../../../components/logo/LogoIcon"
import HeroUnorderedList from "../../../components/hero/HeroUnorderedList"

const TechConsultingSection: React.FC = () => {
  return (
    <HeroService
      heading='Technical Consulting'
      imageUrl={"/img/consulting.jpg"}
      left={
        <Box p={4} ml={8} fontSize='xl' fontWeight='bold'>
          <HeroUnorderedList>
            <ListItem>Software Project Planning</ListItem>
            <ListItem>Application Refactoring</ListItem>
            <ListItem>Application Maintenance</ListItem>
            <ListItem>Critical Issue Resolution</ListItem>
            <ListItem>Continuous Integration</ListItem>
          </HeroUnorderedList>
        </Box>
      }
      marquee={style => (
        <Marquee
          items={[
            <LogoIcon style={style} logo='tableau-icon' />,
            <LogoIcon style={style} logo='shopify' />,
            <LogoIcon style={style} logo='wordpress-icon' />,
            <LogoIcon style={style} logo='google-analytics' />,
            <LogoIcon style={style} logo='google-optimize' />,
            <LogoIcon style={style} logo='google-ads' />,
            <LogoIcon style={style} logo='mailchimp' />,
            <LogoIcon style={style} logo='sentry-icon' />,
            <LogoIcon style={style} logo='jira' />
            // <LogoIcon style={style} logo="tux"/>,
            // "devicon-redux-original",
            // "devicon-webpack-plain",
            // "devicon-express-original",
            // "devicon-laravel-plain",
            // "devicon-django-plain",
            // "devicon-flask-original",
          ]}
        />
      )}
    >
      We help with: identifying short and long term technical needs for your
      business; problem resolution; technical advise; and much more.
    </HeroService>
  )
}

export default TechConsultingSection
