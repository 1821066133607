import React from "react"
import Marquee from "../../../components/animations/Marquee"
import HeroService from "../../../components/hero/HeroService"
import { ListItem } from "@chakra-ui/react"
import LogoIcon from "../../../components/logo/LogoIcon"
import HeroUnorderedList from "../../../components/hero/HeroUnorderedList"

const FullStackDevSection: React.FC = () => {
  return (
    <HeroService
      heading='Full-Stack Development'
      imageUrl='/img/code.jpg'
      marquee={style => (
        <Marquee
          items={[
            <LogoIcon style={style} logo='python' />,
            <LogoIcon style={style} logo='php' />,
            <LogoIcon style={style} logo='typescript-icon' />,
            <LogoIcon style={style} logo='nodejs-icon' />,
            <LogoIcon style={style} logo='java' />,
            <LogoIcon style={style} logo='html-5' />,
            <LogoIcon style={style} logo='css-3' />,
            <LogoIcon style={style} logo='sass' />,
            // <LogoIcon style={style} logo="kotlin"/>,
            // <LogoIcon style={style} logo="rust"/>,
            <LogoIcon style={style} logo='mocha' />,
            <LogoIcon style={style} logo='bootstrap' />,
            <LogoIcon style={style} logo='nestjs' />,
            <LogoIcon style={style} logo='react' />,
            <LogoIcon style={style} logo='memcached' />,
            <LogoIcon style={style} logo='selenium' />,
            <LogoIcon style={style} logo='bulma' />,
            <LogoIcon style={style} logo='redux' />,
            <LogoIcon style={style} logo='webpack' />,
            <LogoIcon style={style} logo='express' />,
            <LogoIcon style={style} logo='laravel' />,
            <LogoIcon style={style} logo='django' />,
            <LogoIcon style={style} logo='flask' />
          ]}
        />
      )}
      right={
        <HeroUnorderedList>
          <ListItem>Modern Web Applications</ListItem>
          <ListItem>Business Logic Implementation</ListItem>
          <ListItem>Distributed Services &amp; APIs</ListItem>
          <ListItem>Automation &amp; Content Scraping</ListItem>
          <ListItem>i18n &amp; Accessibility</ListItem>
        </HeroUnorderedList>
      }
    >
      We provide full-stack software development services for both business and
      customer facing applications. We can help you make your project a reality.
    </HeroService>
  )
}

export default FullStackDevSection
