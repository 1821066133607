import * as React from "react"
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react"
import HomePage from "./pages/home/Home.page"
import Navbar from "./components/layout/navbar/Navbar"
import "devicon/devicon-base.css"

const theme = extendTheme({
  fonts: {
    heading: "museo-sans",
    body: "temeraire"
  }
})

export const App = () => (
  <ChakraProvider theme={theme}>
    <Navbar />
    <Box as={"main"}>
      <HomePage />
    </Box>
  </ChakraProvider>
)
