import * as React from "react"
import { CSSProperties } from "react"
import { Icon } from "@iconify/react"

const LogoIcon: React.FC<{
  style?: CSSProperties
  logo: string
}> = ({ style, logo }) => {
  return (
    <Icon
      {...{ width: style?.width, height: style?.height }}
      style={style}
      icon={`logos:${logo}`}
    />
  )
}

export default LogoIcon
