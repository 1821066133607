import React from "react"
import Marquee from "../../../components/animations/Marquee"
import HeroService from "../../../components/hero/HeroService"
import LogoIcon from "../../../components/logo/LogoIcon"
import { ListItem } from "@chakra-ui/react"
import HeroUnorderedList from "../../../components/hero/HeroUnorderedList"

const DevOpsSection: React.FC = () => {
  return (
    <HeroService
      imageUrl={"/img/server.jpg"}
      heading='IT & DevOps'
      marquee={style => (
        <Marquee
          items={[
            <LogoIcon style={style} logo='github' />,
            <LogoIcon style={style} logo='gitlab' />,
            <LogoIcon style={style} logo='ansible' />,
            <LogoIcon style={style} logo='vault' />,
            <LogoIcon style={style} logo='cloudflare' />,
            <LogoIcon style={style} logo='google-cloud' />,
            <LogoIcon style={style} logo='aws' />,
            <LogoIcon style={style} logo='azure-icon' />,
            <LogoIcon style={style} logo='mysql' />,
            <LogoIcon style={style} logo='mariadb' />,
            <LogoIcon style={style} logo='postgresql' />,
            <LogoIcon style={style} logo='rabbitmq' />,
            <LogoIcon style={style} logo='jenkins' />,
            <LogoIcon style={style} logo='docker' />,
            <LogoIcon style={style} logo='kubernetes' />,
            <LogoIcon style={style} logo='elasticsearch' />,
            <LogoIcon style={style} logo='redis' />,
            <LogoIcon style={style} logo='nginx' />,
            <LogoIcon style={style} logo='apache' />,
            <LogoIcon style={style} logo='kafka' />
          ]}
        />
      )}
      right={
        <HeroUnorderedList>
          <ListItem>Linux Fleet Management</ListItem>
          <ListItem>Application Installation</ListItem>
          <ListItem>Other Requests</ListItem>
        </HeroUnorderedList>
      }
    >
      We can help supervise, configure, and maintain IT operations for your
      services, to ensure everything is kept running smoothly.
    </HeroService>
  )
}

export default DevOpsSection
