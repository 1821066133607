import React from "react"
import { Flex, Grid } from "@chakra-ui/react"
import HeroHeading from "../../pages/home/hero/HeroHeading"

const Hero: React.FC = () => {
  return (
    <Grid
      style={{
        background: "url(/img/hero.jpg)",
        backgroundSize: "cover",
        overflow: "hidden"
      }}
      minH='500px'
      justifyContent='center'
    >
      <Flex
        alignItems='center'
        justifyContent='center'
        pt={{ base: 4 }}
        w='100vw'
        style={{
          backdropFilter: "contrast(35%) brightness(175%) saturate(140%)"
        }}
      >
        <HeroHeading />
      </Flex>
    </Grid>
  )
}

export default Hero
